
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      date: new Date(),
      startDate: new Date(),
      endDate: '',
      span: 7,
      selectedPeriod: {
        start: new Date(), // Jan 16th, 2018
        end: new Date() // Jan 19th, 2018
      }
    };
  },
  created() {
    if (this.$route.params.reportId) {
      const reportTypeContains = this.$route.name;
      var reportType = 'WEEKLY';
      if(reportTypeContains.includes('weekly'))
       reportType =  'WEEKLY';
      else if(reportTypeContains.includes('monthly'))
       reportType =  'MONTHLY';
      this.getSingleReport(this.$route.params.reportId,reportType);
    }
  },
  methods: {
    ...mapActions('periodicReport', ['getSingleReport'])
  }
};
